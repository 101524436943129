.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  .header-toolbar {
    .date-input{
        margin-right: 10px;
        border-radius: 10px;
        background: #fff;
    }
    .shift-btn {
        margin-right: 10px;
    }
    .attendance-btn{
        border-radius: 4px;
        margin-right: 10px;
        box-shadow: none;
    }
    .back-btn{
      background: #d4d4d4
    }
   
  }
}
.tasks-body_child {
  width: 100%;
  position: relative;
}
.tasks-child_status {
  position: absolute;
  z-index: -1;
  width: 100%;
  min-height: 20px;
  height: 23px;
}
.tasks-body_child {
  width: 100%;
  position: relative;
  min-height: 51px;
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  overflow: auto;
  background: #f2f2f2;
  padding: 5px 0px;
  border-radius: 4px;
  margin-top: 6px;
  border: 1px solid #e2e2e2;
}
.date_style{
  background-color: #fff;
  width:180px;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  border: none;
}
.header-toolbar{
  display: flex;
  justify-content: space-between;
}

.pagination-table-bg{
  border-top: 2px solid #ccc;
  background: #fff;
  margin: 14px auto;
  border-radius: 4px;
}
