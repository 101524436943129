.root_containter {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
}
.box_container {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  width: 30%;
  padding: 25px;
  margin-top: 50px;
  border-radius: 5px;
}
.date_div {
  margin-top: 20px;
}
.btn_submit {
  margin-top: 20%;
}
.root_btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.report-btn {
  border-radius: 10px;
}

.generate_paper{
  background: white;
  padding: 26px 22px;
  width: 35%;
  margin: 0 auto;
  border-radius: 8px;
}
.form_div_generate{
  margin-bottom: 20px;
}
.form_btn_generate{
  display: flex;
  justify-content: center;
}
.my-modal {
  width: 100vw !important;  /* Occupy the 90% of the screen width */
  max-width: 100vw !important;
  height: 100vh !important;
  margin: 0 !important;
} 
.modal-content {
  height: 100vh !important;
}
.tablefooter{
  position: relative;
}
// .tablefooter th, td {
//   &:first-child {
//     width: 100%;
//     background: #fff;
//   }
// }
.tpagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: white;
  margin-top: 10px;
  border-radius: 4px;
}