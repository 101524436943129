.loader-container {
    top: 0px;
    left: 0px;
    position: fixed;
    text-align: center;
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: 0.5;
  }
  .loader-container .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2147483647;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
  }
 
 
  