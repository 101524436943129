.login-section {
    background-image: url("../../../../assets/images/login-bg.png");

    height: 100vh;
    background-size: cover;
    width: 100%;

    .backdrop {
        height: 100vh;
        width: 100vw;
        backdrop-filter: blur(4px);
    }

    .inputStyle {
        width: 100% !important;
        height: 45px;
        border-radius: 7px;
        border: 0px;
        margin-left: 8px;
        margin-right: 8px;
        background: #dddddd;
        font-size: 20px;
    }

    .text-error {
        margin: 3px;
    font-size: 12px;
    color: red;
    text-align: right;
    }
    
}